import React, {useCallback, useEffect, useState} from "react";
import {Accordion, Alert, Card, Col, Row, Spinner} from "react-bootstrap";
import LandlordHeaderButton from "./LandlordHeaderButton";
import {get_axios_error, ReduxState} from "../../../helpers/general";
import {setWarning} from "../../../features/notifications/NotificationSlice";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {refreshStats} from "../../../features/General";
import {ProfileInterface} from "../../../features/auth/ProfileSlice";

interface Stats{
	properties: number,
	leases: number,
	unpaidInvoices: number,
	unconfirmedPayments: number
}

interface Props {
	profile: ProfileInterface
}

function LandlordHeader({profile}:Props) {
	const dispatch = useDispatch()
	const [fetched, setFetched] = useState<boolean>(!(profile.loggedIn===true && profile.role.name==='MANAGER'))
	const [stats, setStats] = useState<Stats>({leases: 0, properties: 0, unconfirmedPayments: 0, unpaidInvoices: 0})
	const general = useSelector((state:ReduxState)=>state.general)
	
	const countProperties = useCallback((signal: AbortSignal) => {
		window.axios.get('report/stats', {signal})
			.then(response=>{
				setStats(response.data)
			})
			.catch(error=>{
				const msg = get_axios_error(error)
				dispatch(setWarning( msg.message ))
			})
			.finally(()=>{
				setFetched(true)
				if(general.refreshStats) dispatch(refreshStats(false))
			})
	},[dispatch, general.refreshStats])
	
	useEffect(()=>{
		// console.log('refresh stats is: ')
		// console.log(general.refreshStats)
		const controller = new AbortController()
		let isSubscribed = true;
		let timer = 0
		if( isSubscribed && (!fetched || general.refreshStats)){
			timer = window.setTimeout(()=>{
				countProperties(controller.signal)
			},2000)
		}

		return function cleanup() {
			controller.abort();
			isSubscribed = false
			if(timer) window.clearTimeout(timer)
		}
	},[fetched, countProperties, profile.loggedIn, profile.role.name, general.refreshStats])

	if(profile.loggedIn===true && profile.role.name==='MANAGER') {
		return (
			<Accordion defaultActiveKey="0" className="mb-5">
				<Card className="rounded-0">
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 py-3">

								<Col>
									<Card className="mb-0 radius-10">
										<Card.Body>
											<NavLink to={'/properties'} className="d-flex align-items-center">
												<div>
													<p className="mb-0 text-secondary">Properties</p>
													{fetched ? <h4 className="my-1">{stats.properties}</h4> :
														<Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
												</div>
												<div className="text-primary ms-auto font-35"><i
													className="bx bx-building-house"/></div>
											</NavLink>
										</Card.Body>
									</Card>
									<div className="mb-3 d-block d-xl-none" />
								</Col>

								<Col>
									<Card className="mb-0 radius-10">
										<Card.Body>
											<NavLink to={'/leases'} className="d-flex align-items-center">
												<div>
													<p className="mb-0 text-secondary">Leases</p>
													{fetched ? <h4 className="my-1">{stats.leases}</h4> :
														<Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
												</div>
												<div className="text-primary ms-auto font-35"><i
													className="bx bx-file"/></div>
											</NavLink>
										</Card.Body>
									</Card>
									<div className="mb-3 d-block d-xl-none" />
								</Col>

								<Col>
									<Card className="mb-0 radius-10">
										<Card.Body>
											<div className="d-flex align-items-center">
												<div>
													<p className="mb-0 text-secondary">Unpaid Bills</p>
													{fetched ? <h4 className="my-1">{stats.unpaidInvoices}</h4> :
														<Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
												</div>
												<div className="text-primary ms-auto font-35"><i
													className="bx bx-detail"/></div>
											</div>
										</Card.Body>
									</Card>
									<div className="mb-3 d-block d-xl-none" />
								</Col>

								<Col>
									<Card className="mb-0 radius-10">
										<Card.Body>
											<div className="d-flex align-items-center">
												<div>
													<p className="mb-0 text-secondary">Approval Requests</p>
													{fetched ? <h4 className="my-1">{stats.unconfirmedPayments}</h4> :
														<Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
												</div>
												<div className="text-primary ms-auto font-35"><i
													className="bx bx-badge-check"/></div>
											</div>
										</Card.Body>
									</Card>
								</Col>

							</Row>
							{fetched && stats !== null && stats.properties === 0 && <Row className="px-3">
								<Alert variant={'primary'}>
								<span className="fs-5">
									You have not registered any properties yet. <NavLink to={'/properties'}
																						 className={'alert-link'}>Go to Properties</NavLink> and add a new property.
								</span>
								</Alert>
							</Row>}
							{fetched && stats !== null && stats.properties > 0 && stats.leases === 0 && <Row className="px-3">
								<Alert variant={'primary'}>
								<span className="fs-5">
									You have not add any leases yet. <NavLink to={'/leases'} className={'alert-link'}>Go to Leases</NavLink> and add a new lease.
								</span>
								</Alert>
							</Row>}
						</Card.Body>
					</Accordion.Collapse>
					<div className={'position-relative'}>
						<LandlordHeaderButton eventKey="0"/>
					</div>
				</Card>
			</Accordion>
		)
	}
	else return null
}

export default LandlordHeader