import React, {useEffect, useRef, useState} from 'react';
import Notifications from "../../components/common/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {switchProfile} from "../../features/auth/ProfileSlice";
import {Button, Container, Row} from "react-bootstrap";
import "../../App.css"
import ClientHeader from "../../components/client/ClientHeader";
import MainContent from "../../components/admin/MainContent";
import ClientFooter from "../../components/client/ClientFooter";
import {useLogout} from "../../helpers/session";
import {useAuth} from "../../helpers/auth";
import {ReduxState, useIsAdmin} from "../../helpers/general";
import {useHistory} from "react-router-dom";

type Props = {
	children ?: any,
	history?: any
}
function HomeLayout(props:Props) {
	const history = useHistory()
	const logoff = useLogout()
	const {getProfile} = useAuth()
	const dispatch = useDispatch()
	const profile = useSelector((state: ReduxState) => state.profile)
	const container = useRef(null)
	const [currentClass, setCurrentClass] = useState('');

	const logout = (event: { preventDefault: () => void; }) => {
		event.preventDefault()
		logoff()
		props.history.push('/')
	}
	
	const storedProfile = getProfile()
	if( profile.role.name !== storedProfile ){
		//dispatch(switchProfile(storedProfile))
		console.log("please update stored profile")
	}
	
	useEffect(() => {
		if(container.current != null){
			if(history.location.pathname === "/") {
				// @ts-ignore
				container.current.setAttribute('style', 'background: #fff url("/images/banner1-6.jpg") no-repeat')
				setCurrentClass("overlay overlay-dark")
			}
			else{
				// @ts-ignore
				container.current.setAttribute('style', 'background: #FFF')
				setCurrentClass("")
			}
		}
	},[history.location.pathname]);
	
	
	if(!profile.loggedIn) {
		return (
			<div ref={container}>
				<div className={currentClass}>
					<Container className={'client bg-transparent'}>
						<Row>
							<ClientHeader loggedIn={profile.loggedIn} />
							<MainContent profile={profile} style={{marginLeft:'0'}}/>
						</Row>
						<ClientFooter />
						<Notifications/>
					</Container>
				</div>
			</div>
		)
	}
	else{
		return (
			<div className="m-5">
				<h2>{profile.role.alias ? profile.role.alias : profile.role.name} view is currently not available</h2>
				<div className="my-5">
					<h3>Try a different Profile</h3>
					{profile.roles.map(role=>{
						return (
							<Button key={role.name} variant={"outline-secondary"} className="me-2" type="button" onClick={()=>dispatch(switchProfile(role.name))}>
								{role.alias?role.alias:role.name}
							</Button>
						)
					})}
				</div>
				<p>
					<Button variant={'secondary'} onClick={logout}>Login with a different account</Button>
				</p>
			</div>
		)
	}
}

export default HomeLayout;