import React, {useEffect} from 'react';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import {BrowserRouter as Router,Route, Switch} from 'react-router-dom';
import axios from "axios";
import ResendActivationToken from "./components/admin/account/ResendActivationToken";
import ActivateAccount from "./components/admin/account/ActivateAccount";
import ResetForgottenPasswordRequest from "./components/admin/account/ResetForgottenPasswordRequest";
import ResetForgottenPassword from "./components/admin/account/ResetForgottenPassword";
import {useSelector} from "react-redux";
import HomeLayout from "./app/layouts/HomeLayout";
import {useLoggedIn} from "./helpers/session";
import PleaseWait from "./components/admin/account/PleaseWait";
import {ReduxState} from "./helpers/general";

window.axios = axios
window.axios.defaults.baseURL = window.env.API_URL
window.axios.defaults.withCredentials = false

const AppLayout = React.lazy(() => import('./app/layouts/AppLayout') )

const loading = (
	<div className="py-5 text-center">
		<div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
	</div>)

function App() {
	const checkLogin = useLoggedIn()
	const general = useSelector((state: ReduxState) => state.general)
	const profile = useSelector((state: ReduxState) => state.profile)
	
	useEffect(()=>{
		if(profile.loggedIn==='wait'){
			checkLogin()
		}
	},[checkLogin, profile.loggedIn])
	
	const ProfileRoute = () => {
		if(profile.loggedIn==='wait'){
			return (
				<PleaseWait />
			)
		}
		else if(profile.loggedIn===true){
			return (
				<Route path="/" render={(props: any) => <AppLayout loggedIn={profile.loggedIn} {...props}/>}/>
			)
		}
		else {
			return (
				<Route path="/" render={(props: any) => <HomeLayout {...props}/>}/>
			)
		}
	}
	
	return (
		<div className={general.wrapperToggled?'wrapper toggled':'wrapper'}>
			<Router>
				<React.Suspense fallback={loading}>
					<Switch>
						{/*<Route exact path="/" name="Home Page" render={ props => <HomeLayout {...props} /> } />*/}
						{/*<Route exact path="/login" name="Login Page" render={ props => <Login {...props} /> } />*/}
						<Route exact path="/resend-activation-token" render={ (props:any) => <ResendActivationToken {...props} /> } />
						<Route exact path="/forgot-password" render={ (props:any) => <ResetForgottenPasswordRequest {...props} /> } />
						<Route exact path="/user/reset-password/:token" render={ (props:any) => <ResetForgottenPassword {...props} /> } />
						<Route exact path="/user/confirm-account/:token" render={ (props:any) => <ActivateAccount {...props} /> } />
						{/*<Route exact path="/create-account" name="Registration Page" render={ props => <CreateAccount {...props} /> } />*/}
						{/*<AuthenticatedRoute path="/home" name="Home" render={props => <AppLayout {...props}/>}/>*/}
						<ProfileRoute />
					</Switch>
				</React.Suspense>
			</Router>
		</div>
	);
}

export default App;
