import React, {useEffect, useState} from 'react';
import {Container, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useHistory} from "react-router-dom";
import {useLogout} from "../../helpers/session";
import {switchProfile} from "../../features/auth/ProfileSlice";
import {ReduxState} from "../../helpers/general";

function ClientHeader({loggedIn}:{loggedIn:boolean|string}) {
	const history = useHistory()
	const dispatch = useDispatch()
	const logoff = useLogout()
	const profile = useSelector((state:ReduxState) => state.profile)
	const [loadProfile, setLoadProfile] = useState(true)
	const [logo, setLogo] = useState('ptracker-logo-white.png');
	
	const switchRole = (event: any, role: any) => {
		event.preventDefault()
		dispatch(switchProfile(role))
	}
	
	const logout = (event:any) => {
		event.preventDefault()
		logoff()
		history.push('/login')
	}
	
	useEffect(()=>{
		if( loggedIn && loadProfile ){
			setLoadProfile(false)
		}
		setLogo(!loggedIn && history.location.pathname==='/' ? 'ptracker-logo-white.png' : 'ptracker-logo.png')
	},[history.location.pathname, loadProfile, loggedIn])
	
	const c = !loggedIn && history.location.pathname==='/' ? "navbar-expand-lg rounded rounded-0 shadow-sm navbar-dark" : "navbar-expand-lg rounded rounded-0 shadow-sm  navbar-light"
	return (
		<Navbar className= {c} collapseOnSelect expand="md">
				<Container>
					<NavLink to={'/'} className="navbar-brand">
						<img src={'/assets/images/'+logo} width="140" className="logo-icon" alt="logo icon"/>
					</NavLink>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse  id="responsive-navbar-nav">
						<Nav className="navbar-nav ms-auto mb-2 mb-lg-0">
							<Nav.Item>
								<NavLink to={'/'} className="nav-link" aria-current="page"><i className='bx bx-home-alt me-1'/>Home</NavLink>
							</Nav.Item>
							{loggedIn && profile.role.name==='MANAGER' && <React.Fragment>

								<Dropdown className="nav-item" id="view-dropdown">
									<Dropdown.Toggle as="a" role="button" className="nav-link dropdown-toggle-nocaret">
										<span className="user-img"><i className="bx bx-briefcase" /> View</span>
									</Dropdown.Toggle>
									<Dropdown.Menu as="ul">
										<Dropdown.Item as="li" eventKey="1">
											<NavLink to={'/properties'} className="nav-link"><i className='bx bx-building me-1'/>My Properties</NavLink>
										</Dropdown.Item>
										<Dropdown.Item as="li" eventKey="2">
											<NavLink to={'/leases'} className="nav-link"><i className='bx bx-file me-1'/>My Leases</NavLink>
										</Dropdown.Item>
										<Dropdown.Item as="li" eventKey="3">
											<NavLink to={'/orders'} className="nav-link"><i className='bx bx-list-ul me-1'/>My Orders</NavLink>
										</Dropdown.Item>
										<Dropdown.Item as="li" eventKey="4">
											<NavLink to={'/subscription'} className="nav-link"><i className='bx bx-list-ul me-1'/>My Subscriptions</NavLink>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</React.Fragment>}
							<Nav.Item>
								<NavLink to={'/contact'} className="nav-link"><i className='bx bx-support me-1'/>Contact</NavLink>
							</Nav.Item>
							{!loggedIn && <React.Fragment>
								<Nav.Item><NavLink to={'/login'} className="nav-link"><i className='bx bx-lock me-1'/>Login</NavLink></Nav.Item>
								<Nav.Item><NavLink to={'/create-account'} className="nav-link"><i className='bx bx-user-plus me-1'/>Create Account</NavLink></Nav.Item>
							</React.Fragment>}
							{/*<Nav.Item>
								<NavLink to={'/contact'} className="nav-link"><i className='bx bx-microphone me-1'/>Contact</NavLink>
							</Nav.Item>*/}
							{loggedIn && profile &&
								<Dropdown className="nav-item">
									<Dropdown.Toggle as={"a"} role="button" className="nav-link dropdown-toggle-nocaret">
										<span className="user-img">
											<i className='bx bx-user'/> {profile.firstName}
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end" data-bs-poper="none">
										<li><NavLink to={'/profile'} className="dropdown-item"><i
											className="bx bx-user"/> <span>Profile</span></NavLink></li>
										<li>
											<div className="dropdown-divider mb-0"/>
										</li>
										<li className="mt-1">
											<Dropdown>
												<Dropdown.Toggle as={'span'} role="button" className="dropdown-item">
													<i className="bx bx-street-view"/> <span>Switch View</span>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													{profile.roles.map(role => {
														return <Dropdown.Item key={role.name}
																			  onClick={(event) => switchRole(event, role.name)}>
															{role.alias ? role.alias : role.name}
														</Dropdown.Item>
													})}
												</Dropdown.Menu>
											</Dropdown>
										
										</li>
										<li>
											<div className="dropdown-divider mb-0"/>
										</li>
										<li><a className="dropdown-item" href="/" onClick={logout}><i
											className="bx bx-log-out-circle"/> <span>Logout</span></a></li>
									</Dropdown.Menu>
								</Dropdown>
							}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
	);
}

export default ClientHeader;