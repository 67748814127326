import { createSlice } from '@reduxjs/toolkit'
import {getProfile, setupProfile} from "../../helpers/auth";

interface Role {
	name: string,
	alias: string
}

export interface ProfileInterface {
	role: Role,
	roles: any[],
	username: string,
	firstName: string,
	lastName: string,
	email: string,
	id: string,
	loggedIn: string | boolean,
	phoneNumber: string,
	suspended: boolean
}

const initialState: ProfileInterface = {
	role: {name: getProfile(), alias: ''},
	roles: [],
	username: '',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	suspended: false,
	id: '',
	loggedIn: 'wait'
}

export const ProfileSlice = createSlice({
	name: 'profile',
	initialState: initialState,
	reducers: {
		switchProfile: (state, action) => {
			const role:Role|undefined = state.roles.find((r:Role) => r.name === action.payload)
			if(role){
				localStorage.setItem('profile', role.name)
				state.role = role;
			}
			else{
				localStorage.setItem('profile', 'GUEST')
				state.role = {name: 'GUEST', alias: ''};
			}
		},
		setupUser: (state, action) => {
			const data = action.payload
			if(data.roles){
				const roles = data.roles.map((role:Role) => role.name)
				if( !roles.includes(state.role.name) ){
					const defaultRole = setupProfile(roles)
					const role = data.roles.find((r:Role) => r.name === defaultRole)
					if(role) state.role =role;
					// state.roles = roles
				}
			}
			state.roles = data.roles
			state.email = data.email
			state.firstName = data.firstName
			state.lastName = data.lastName
			state.username = data.username
			state.id = data.id
			state.phoneNumber = data.phoneNumber
			state.suspended = data.suspended
		},
		setLoginStatus: (state, action) => {
			// @ts-ignore
			window.loggedIn = action.payload
			state.loggedIn = action.payload
		},
		resetSession: (state) => {
			state.roles = []
			state.email = ''
			state.firstName = ''
			state.lastName = ''
			state.username = ''
			state.id = ''
			state.phoneNumber = ''
			state.suspended = true
			state.loggedIn = false
			localStorage.setItem('profile', 'GUEST')
			state.role = {name: 'GUEST', alias: ''};
		}
	},
})

// Action creators are generated for each case reducer function
export const { switchProfile, setupUser, setLoginStatus, resetSession} = ProfileSlice.actions
export default ProfileSlice.reducer