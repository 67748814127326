import React, {useCallback, useEffect, useState} from 'react';
import {SettingsInterface} from "../admin/settings/settings";
import {useDispatch} from "react-redux";
import {AxiosResponse} from "axios";
import {get_axios_error} from "../../helpers/general";
import {setWarning} from "../../features/notifications/NotificationSlice";

function ClientFooter() {

	const [settings, setSettings] = useState<SettingsInterface>();
	const dispatch = useDispatch()

	const getSettings = useCallback((signal: AbortSignal) => {
			window.axios.get('public/general-settings', {signal})
				.then((response:AxiosResponse<SettingsInterface>)=>{
					setSettings(response.data)
				})
				.catch(error=>{
					const e = get_axios_error(error)
					dispatch(setWarning(e.message))
				})
		},[dispatch]
	)

	useEffect(() => {
		const controller = new AbortController()
		let subscribed = true
		let timer = 0
		if(subscribed) {
			timer = window.setTimeout(()=>{
				getSettings(controller.signal)
			},2000)
		}
		return(()=>{
			controller.abort();
			subscribed = false
			if(timer) window.clearTimeout(timer)
		})
	},[getSettings])

	const year = new Date().getFullYear()
	return (
		<div className="bg-white p-3 fixed-bottom border-top shadow text-dark">
			<div className="d-flex align-items-center justify-content-between flex-wrap">
				{settings && <ul className="list-inline mb-0">
					<li className="list-inline-item">Follow Us :</li>
					{settings.facebook && <li className="list-inline-item">
						<a href={settings.facebook} rel="noreferrer" target="_blank"><i
							className='bx bxl-facebook me-1'/>Facebook</a>
					</li>}
					{settings.twitter && <li className="list-inline-item">
						<a href={"https://twitter.com/" + settings.twitter} rel="noreferrer" target="_blank"><i
							className='bx bxl-twitter me-1'/>Twitter</a>
					</li>}
				</ul>}
				<p className="mb-0">Payments Tracker. Copyright &copy; 2021 - {year}. All rights reserved.</p>
			</div>
		</div>
	)
}

export default ClientFooter;