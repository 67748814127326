import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {resetSession, setLoginStatus, setupUser} from "../features/auth/ProfileSlice";
import {useAuth} from "./auth";
import {ReduxState} from "./general";

export const useLoggedIn = () => {
	const profile = useSelector((state:ReduxState) => state.profile)
	const dispatch = useDispatch()
	const {isLoggedIn, getRefreshToken, refreshAccessToken, setupSessionTokens, destroySession} = useAuth()
	
	const getUserProfile = useCallback(() => {
		window.axios.get('/profile')
			.then(response => {
				if(response.data && response.data.id){
					dispatch(setupUser(response.data))
				}
				else{
					console.log(response)
				}
			})
			.catch(error=>{
				console.log(error)
			})
	},[dispatch])
	
	const checkUserToken = () => {
		// check refresh token
		const refreshToken = getRefreshToken()
		if (!refreshToken){
			// console.log("you have no refresh token, dispatch logout")
			destroySession()
			dispatch(setLoginStatus(false))
		}
		else {
			// attempt refresh
			// console.log("you have a refresh token, refresh the token")
			refreshAccessToken()
				.then(response => {
					if(response.data.accessToken){
						setupSessionTokens(response.data, true, true)
						getUserProfile()
						dispatch(setLoginStatus(true))
					}
					else{
						dispatch(setLoginStatus(false))
						destroySession()
					}
				})
				.catch(error=>{
					console.log(error)
					dispatch(setLoginStatus(false))
					destroySession()
				})
		}
	}
	
	return () => {
		const loggedIn = isLoggedIn()
		if(loggedIn){
			// console.log("it says you're logged in")
			if(!profile.id){
				checkUserToken()
			}
			
			dispatch(setLoginStatus(true))
		}
		else{
			checkUserToken()
		}
	}
	
}

export const useLogout = () => {
	const dispatch = useDispatch()
	const {destroySession} = useAuth()
	return () => {
		dispatch(resetSession())
		destroySession()
	}
}