import jwt_decode from "jwt-decode";
import axios, { AxiosRequestConfig } from "axios";
import {useDispatch} from "react-redux";
import {setLoginStatus} from "../features/auth/ProfileSlice";
import Interceptors from "./Interceptors";
import {useHistory} from "react-router-dom";

export const useAuth = () => {
	const history = useHistory()
	const refreshTokenName = 'jXEwXmC3qvGkzOzL'
	const accessTokenName = 'axM8pgtR97Qvr8ch'
	const dispatch = useDispatch()

	const createSession = (username: string, tokens: { accessToken: string; refreshToken: string; }, remember: any) => {
		setAxiosBearerHeader(tokens.accessToken, true)
		setAxiosResponseInterceptor(tokens.refreshToken ? tokens.refreshToken : '')
		const decoded: { exp: number, roles: string[] } = jwt_decode(tokens.accessToken);
		sessionStorage.setItem('user', username)
		sessionStorage.setItem(accessTokenName, tokens.accessToken)
		sessionStorage.setItem('accessExpires', String(decoded.exp))
		Interceptors.accessTokenExpires = decoded.exp * 1000
		if (tokens.refreshToken) {
			const decodedRefreshToken: { exp: number } = jwt_decode(tokens.refreshToken)
			const options = remember ? {expires: decodedRefreshToken.exp} : {}
			setCookie(refreshTokenName, tokens.refreshToken, options)
			sessionStorage.setItem(refreshTokenName, tokens.refreshToken)
		}
		return setupProfile(decoded.roles)
	}

	const setProfile = (profile: any) => {
		localStorage.setItem('profile', profile)
	}

	const destroySession = () => {
		sessionStorage.removeItem('user')
		sessionStorage.removeItem(accessTokenName)
		sessionStorage.removeItem(refreshTokenName)
		deleteCookie(refreshTokenName)
		if (Interceptors.requestInterceptor != null) {
			window.axios.interceptors.request.eject(Interceptors.requestInterceptor)
		}
		if (Interceptors.responseInterceptor != null) {
			window.axios.interceptors.response.eject(Interceptors.responseInterceptor)
		}
	}

	const isLoggedIn = () => {
		const user = sessionStorage.getItem('user')
		const token = sessionStorage.getItem(accessTokenName)
		return !!(user && token)
	}

	const getAccessToken = () => {
		return sessionStorage.getItem(accessTokenName)
	}

	const getRefreshToken = () => {
		return getCookie(refreshTokenName)
	}

	const tokenExpired = (token: string) => {
		const decoded: { exp: number, iat: number } = jwt_decode(token)
		const exp = decoded.exp
		const iat = decoded.iat

		if (exp && iat) {
			const curTime = new Date()
			const tokenExpires = new Date((exp - 5) * 1000)
			const tokenIssued = new Date(iat * 1000)
			return tokenExpires < curTime || tokenIssued > curTime
		}
		return true;
	}

	const refreshAccessToken = (msg: string = '') => {
		const refreshToken = getRefreshToken()
		if (refreshToken && !tokenExpired(refreshToken)) {
			const uninterceptedAxiosInstance = axios.create();
			uninterceptedAxiosInstance.defaults.headers.authorization = "Bearer " + refreshToken
			return uninterceptedAxiosInstance.get('/token/refresh')
		} else {
			destroySession()
			msg = msg ? msg : 'No valid refresh token found. Destroyed current session'
			//error.message = msg
			return Promise.reject(new Error(msg))
		}
	}

	const setupSessionTokens = (tokens: { accessToken: string; }, force = false, updateResponse = false) => {
		sessionStorage.setItem(accessTokenName, tokens.accessToken)
		setAxiosBearerHeader(tokens.accessToken, force)
		if (updateResponse) setAxiosResponseInterceptor()
	}

	const setAxiosBearerHeader = (accessToken: string = '', force: boolean = false) => {
		const token = accessToken ? accessToken : getAccessToken()
		if (Interceptors.requestInterceptor != null) {
			window.axios.interceptors.request.eject(Interceptors.requestInterceptor)
		}
		if ((isLoggedIn() || force) && token) {
			Interceptors.requestInterceptor = window.axios.interceptors.request.use(async function (config) {
				const expired = tokenExpired(token)
				if (expired) {
					await refreshAccessToken('unauthorized')
						.then(response => {
							const t = response.data.accessToken
							config.headers.authorization = 'Bearer ' + t
						})
						.catch(error => {
							throw error
						})
				} else {
					config.headers.authorization = 'Bearer ' + token
				}
				return config;
			}, function (error) {
				// Do something with request error
				return Promise.reject(error);
			});
		}
	}

	const resetInterceptors = () => {
		if (Interceptors.requestInterceptor != null) window.axios.interceptors.request.eject(Interceptors.requestInterceptor)
		if (Interceptors.responseInterceptor != null) window.axios.interceptors.response.eject(Interceptors.responseInterceptor)
	}

	const setAxiosResponseInterceptor = (token: string = '') => {
		// return false
		token = token ? token : getRefreshToken();
		if (token) {
			if (Interceptors.responseInterceptor != null) window.axios.interceptors.response.eject(Interceptors.responseInterceptor)
			Interceptors.responseInterceptor = window.axios.interceptors.response.use((response) => {
				//console.log("Normal response interceptor")
				return response
			}, async function (error) {
				const originalRequest = error.config;
				if (error.response && error.response.status && error.response.status === 403 && !originalRequest._retry && originalRequest.url !== 'login') {

					refreshAccessToken()
						.then(response => {
							setupSessionTokens(response.data, true)
							resendRequest(response.data.accessToken, originalRequest, error)
						})
						.catch(() => {
							resetInterceptors()
							dispatch(setLoginStatus(false))
						})
				} else if (error && error.message && error.message === 'unauthorized') {
					window.setTimeout(() => {
						const currentUrl = history.location.pathname
						history.push('/login?r=' + currentUrl)
					}, 1000)
				}
				return Promise.reject(error);
			});
		} else {
			if (Interceptors.responseInterceptor != null) {
				window.axios.interceptors.response.eject(Interceptors.responseInterceptor)
			}
		}
	}

	const resendRequest = (accessToken: string, originalRequest: AxiosRequestConfig, error: any) => {
		const newAxiosInstance = axios.create();
		// @ts-ignore
		originalRequest._retry = true;
		if(!accessToken) return Promise.reject(error)
		newAxiosInstance.defaults.headers.authorization = 'Bearer ' + accessToken;
		originalRequest.headers.authorization = 'Bearer ' + accessToken;
		//window.axios.defaults.headers.authorization = 'Bearer ' + access_token;
		setAxiosBearerHeader(accessToken)
		//console.log(window.axios.interceptors.request)
		return newAxiosInstance(originalRequest);
	}
	
	const setCookie = (name: string, value: string, options:any={}) => {
		let cs = name+'='+value+';'
		if(!options.hasOwnProperty('path')) options.path = '/'
		if( options.hasOwnProperty('expires') && !Number.isNaN(options.expires)){
			const date = new Date(options.expires*1000)
			options.expires = date.toUTCString()
		}
		for( const key in options ){
			if(options.hasOwnProperty(key)){
				cs += ' '+key+'='+options[key]+';'
			}
		}
		document.cookie = cs;
	}
	
	const deleteCookie = (name: string) => {
		document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	}
	
	const getCookie = (name: string) => {
		const array = document.cookie.split('; ')
		if(array.length===0) return ''
		const row = array.find(row => row.startsWith(name+'='))
		if(!row) return ''
		const data = row.split('=')
		if(data.length>1) return data[1]
		return ''
	}
	
	
	return {createSession, setupProfile, getProfile, setProfile, destroySession, resendRequest, refreshAccessToken,
		setupSessionTokens, setAxiosBearerHeader, setAxiosResponseInterceptor, isLoggedIn, getRefreshToken}
}


export const setupProfile = (roles:string[]) => {
	let profile:string
	if(roles){
		if(roles.includes('ADMIN') || roles.includes('SUPER_ADMIN')){
			localStorage.setItem('profile', 'ADMIN')
			profile = 'ADMIN'
		}
		else if( roles.includes('MANAGER') ){
			localStorage.setItem('profile', 'MANAGER')
			profile = 'MANAGER'
		}
		else if( roles.includes('USER') ){
			localStorage.setItem('profile', 'USER')
			profile = 'USER'
		}
		else{
			localStorage.setItem('profile', 'GUEST')
			profile = 'GUEST'
		}
	}
	else{
		localStorage.setItem('profile', 'GUEST')
		profile = 'GUEST'
	}
	return profile
}

export const getProfile = () => {
	return localStorage.getItem('profile') || 'GUEST'
}