import { useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import React from "react";
import {pdfHtml} from "../components/admin/leases/pdfHtml";
import {GeneralInterface} from "../features/General";
import {ProfileInterface} from "../features/auth/ProfileSlice";
import {AxiosError} from "axios";
import {NotificationInterface} from "../features/notifications/NotificationSlice";

export interface AlertProps {
	show: boolean,
	message?: string,
	variant?: string,
	heading?: string
}

export interface ReduxState {
	general: GeneralInterface,
	profile: ProfileInterface,
	notifications: NotificationInterface[]
}

export interface PaginatedData{
	items: any[],
	totalPages: number,
	currentPage: number,
	totalItems: number
}

/*export const SetMessage = (message:string, flag:string) => {
	useDispatch({type: 'pushToast', payload: message, flag: flag})
}*/

export const useIsAdmin = () => {
	const profile = useSelector((state: ReduxState) => state.profile)
	return profile && (profile.role.name==='ADMIN' || profile.role.name==='SUPER_ADMIN' )
}

export const useIsAdminPath = () => {
	const history = useHistory()
	return useIsAdmin() && history.location.pathname.includes('/admin/')
}

export function get_axios_error(error:AxiosError){
	let msg:any = '';
	let statusCode: number = 0
	if (error.response && error.response.status) {
		const r = error.response
		console.log("error response:")
		console.log(error.response)
		msg = r.data.message ? r.data.message : r.data;
		if(msg===undefined || msg===null || msg===''){
			switch (r.status){
				case 403 :
					msg = "You do not have permission to access this resource"
					break
				case 401:
					msg = "The requested resource could not be found"
					break
				default:
					msg = "Unknown error. The server return error code "+r.status
					break
			}
			statusCode = r.status
		}
	}
	else if (error.request) {
		console.log("error request:")
		console.log(error.request)
		msg = error.request
	}
	else if(error.message){
		if(error.message === 'unauthorized') {
			msg = 'Your session has expired. You need to login to continue'
			statusCode = 401
		}
		else msg = error.message
	}
	else{
		console.log(error)
	}
	
	if( msg instanceof Object){
		msg = msg.message || msg.exception
	}
	
	if(error.response && error.response.data && error.response.data.errors){
		const errors = error.response.data.errors
		let blocks = [];
		for( const i in errors ){
			const block = '<li>'+errors[i].join('</li>')+'</li>'
			blocks.push(block)
		}
		msg += '<ul>' + blocks.join('')+'</ul>'
	}
	
	return {message: msg, status: 'warning', statusCode: statusCode}
}

export function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const convertSort = (data:any) => {
	let sortObj = data;
	if( data && (typeof data === 'string' || data instanceof String) ){
		const exploded = data.split(':');
		if(exploded && exploded.length > 1){
			sortObj = {field: exploded[0], dir: exploded[1]}
			return sortObj;
		}
	}
	return { field: 'created', dir: 'desc' }
}

export const leftPad = (num: number, length: number) => {
	return num.toString().padStart(length, '0');
}

export const generatePdf = (frameRef: React.MutableRefObject<any>, fileName: string) => {
	const doc = frameRef.current.contentWindow.document
	const body = doc.body
	const canvases = doc.querySelectorAll('.canvas')
	canvases.forEach( (div:HTMLDivElement)=>{
		const canvas = div.querySelector('canvas')
		if(canvas){
			const src = canvas.toDataURL('image/png', 1.0)
			div.innerHTML = '<img alt="canvas" src="'+src+'" />'
		}
	})
	const html = pdfHtml.replace('HTML_BODY', body.innerHTML)
	window.axios.post('report/pdf', {name: fileName, html:html})
		.then(response=>{
			downloadPdf(response.data, fileName)
		})
		.catch(error=>{
			console.log(error)
		})
}

const downloadPdf = (file:string, fileName:string) => {
	// @ts-ignore
	window.axios.get('report/pdf?file='+file, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}})
		.then(response=>{
			const file = 'tenant-report-'+fileName.substring(0,8)+'.pdf'
			
			const blob = new Blob([response.data], { type: 'application/pdf' })
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file);
			const links = document.getElementById("download-link");
			if(links !== null) {
				links.appendChild(link);
				link.click();
				links.innerHTML = ''
			}
		})
		.catch(error=>{
			console.log(error)
		})
}

export const dateString = (date:string) => {
	const d = new Date(date)
	return d.toLocaleString()
}

export const replaceNull = (someObj:any, replaceValue = "") => {
	const replacer = (key:string, value:any) =>
		String(value) === "null" || String(value) === "undefined" ? replaceValue : value;
	return JSON.parse( JSON.stringify(someObj, replacer));
}